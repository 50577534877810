import {embeddingAfterAddResolve} from './embedding'
import {layoutGraph} from './layout'
import {paramGraphConvert} from './param-graph'
import { contextGetParam } from './context'

export const initializeGraph = context => {
  context.graph = new joint.dia.Graph
  context.graph.on('add', (cell, _collection, opt) => {
    if (opt.stencil) {
      const position = {x: event.pageX, y: event.pageY}
      embeddingAfterAddResolve(context, position, cell)
    }
  })
}

export const graphLoad = context => {
  const param = contextGetParam(context)
  context.graph.fromJSON(paramGraphConvert(param.graph))
}

export const graphLayout = context => {
  const param = contextGetParam(context)
  if (param.layout) {
    layoutGraph(context.graph, param.layout)
  }
}