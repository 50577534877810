export const removeDuplicates = (array) => {
  return array.filter((item, pos) => {
    return array.indexOf(item) == pos
  })
}

export const zip = (array1, array2) => {
  return array1.map((item, index) => {
    return [item, array2[index]]
  })
}

export const equals = (value1, value2) => {
  if (value1 === value2) {
    return true
  } else if (value1 === null || value2 === null) {
    return value1 === value2
  } else if (value1 instanceof Array) {
    if (value2 instanceof Array) {
      if (value1.length === value2.length) {
        return zip(value1, value2).every(([item1, item2]) => {
          return equals(item1, item2)
        })
      } else {
        return false
      }
    } else {
      return false
    }
  } else if (typeof value1 === 'object') {
    if (typeof value2 === 'object') {
      const keys1 = Object.keys(value1)
      const keys2 = Object.keys(value2)
      return removeDuplicates([...keys1, ...keys2]).every(key => {
        return equals(value1[key], value2[key])
      })
    } else {
      return false
    }
  } else {
    return false
  }
}

export const clone = value => {
  if (Array.isArray(value)) {
    return value.map(v => clone(v))
  }
  if (value === null) {
    return value
  }
  if (value instanceof Object) {
    const result = {}
    Object.keys(value).forEach(key => {
      result[key] = clone(value[key])
    })
    return result
  }
  return value
}

export const concatArrays = arrays => arrays.reduce((a1, a2) => a1.concat(a2), [])

export const ensureObject = value => {
  if (typeof value === 'undefined' || value === null) {
    return {}
  }
  if (typeof value === 'object') {
    return value
  }
  throw new Error('Can not ensure object.')
}

export const isDefined = value => typeof value !== 'undefined'

export const objectSetPathValue = (object, path, value) => {
  if (path.length === 0) {
    return {...object, ...value}
  }
  const step = path[0]
  if (path.length === 1) {
    return {...object, [step]: value}
  }
  const rest = path.slice(1)
  const nextObject = object[step] || {}
  return {...object, [step]: objectSetPathValue(nextObject, rest, value)}
}