import { callWithoutChange, callAsBatch, contextGetParam } from './context.js'
import { displayContextMenu } from './context-menu.js'
import { jointGetShape } from './joint-shape.js'
import { paramModelIsLinkPossible, paramModelLinkGetTitle, paramModelLinkGetShapes, paramModelGetPossibleLinks, paramModelGetLinkModel } from './param-model'
import { paramGetModel } from './param'
import { embeddingLinkResolve } from './embedding'
import { contextAdjustCellVertices } from './adjust-vertices'
import { objectConvert, booleanConvert } from './param-convert.js'
import { getParamShapeModel } from './data-maps'

export const displayNewLinkContextMenu = async (context, tempLink, event) => {
  const param = contextGetParam(context)
  const model = paramGetModel(param)
  const paperParam = objectConvert(param.paper)
  const ends = getLinkEnds(context, tempLink)
  const paramModelLinks = paramModelGetPossibleLinks(model, ends)
  if (paramModelLinks.length !== 0) {
    const items = paramModelLinks.map(link => {
      return {
        title: paramModelLinkGetTitle(link),
        value: link
      }
    })
    const position = {x: event.pageX, y: event.pageY}
    const paramModelLink = await displayContextMenu(items, context.paper.$el, position)
    callWithoutChange(context, () => {
      tempLink.remove()
    })
    if (paramModelLink) {
      const paramShape = paramModelLinkGetShapes(paramModelLink)[0]
      const shape = jointGetShape(paramShape.type)
      const link = new shape({source: tempLink.get('source'), target: tempLink.get('target')})
      callAsBatch(context, () => {
        context.graph.addCell(link)
        if (booleanConvert(paperParam.adjustLinkVertices, false)) {
          contextAdjustCellVertices(context, link)
        }
      })
      embeddingLinkResolve(link)
    }
  } else {
    callWithoutChange(context, () => {
      tempLink.remove()
    })
  }
}


export const startLinking = (context, shape, cellView, event, x, y) => {
  const link = shape ? new shape : new joint.dia.Link
  link.set('temporary', true)
  if (!shape) {
    link.set('universal', true)
  }
  link.set('source', {
    id: cellView.model.id,
    selector: '.link-target'
  })
  link.set('target', {
    x: x,
    y: y
  })
  callWithoutChange(context, () => {
    context.graph.addCell(link)
    const linkView = context.paper.findViewByModel(link)
    linkView.startArrowheadMove('target')  
    context.creationLinkView = linkView
  })
}

export const doLinking = (context, event, x, y) => {
  callWithoutChange(context, () => {
    context.creationLinkView.pointermove(event, x, y)
  })
}

const getCellEnd = (context, endCell) => {
  const endCellType = endCell.get('type')
  const endCellName = context.shapeTypeModelMap[endCellType].name
  let elementName
  let linkName
  if (endCell.isLink()) {
    linkName = endCellName
  } else {
    elementName = endCellName
  }
  return { linkName, elementName}
}

const getLinkEnd = (context, end) => {
  const endCell = context.graph.getCell(end.id)
  const port = end.port
  return {...getCellEnd(context, endCell), port }
}

const getLinkEnds = (context, link) => {
  const source = link.get('source')
  const target = link.get('target')
  return {
    source: getLinkEnd(context, source),
    target: getLinkEnd(context, target)
  }
}

export const stopLinking = (context, event, x, y) => {
  const linkView = context.creationLinkView
  const param = context.props.data.param
  callWithoutChange(context, () => {
    linkView.pointerup(event)
  })
  const tempLink = linkView.model
  if (tempLink.getTargetElement('target'))
    if (tempLink.get('universal')) {
      displayNewLinkContextMenu(context, tempLink, event)
    } else {
      const ends = getLinkEnds(context, tempLink)
      const linkName = getParamShapeModel(context, tempLink.get('type')).name
      if (paramModelIsLinkPossible(param.model, linkName, ends)) {
        const shape = jointGetShape(tempLink.get('type'))
        let target = tempLink.get('target')
        if (!target.port) {
          target.selector = '.link-target'
        }
        const link = new shape({source: tempLink.get('source'), target})
        context.graph.addCell(link)
        embeddingLinkResolve(link)
      }
      callWithoutChange(context, () => {
        tempLink.remove()
      })
    }
  else {
    callWithoutChange(context, () => {
      tempLink.remove()
    })
  }
}

export const createConnectionValidator = context => {
  const param = contextGetParam(context)
  const model = paramGetModel(param)
  const graph = context.graph
  return (sourceCellView, sourceMagnet, targetCellView, targetMagnet, end, linkView) => {
    if (!linkView) {
      return true
    }
    if (linkView.model.get('temporary') && linkView.model.get('universal')) {
      return true
    }
    const link = linkView.model
    const sourcePort = sourceMagnet ? sourceMagnet.getAttribute('port') || sourceMagnet.className.baseVal.split(' ')[1] : undefined
    const targetPort = targetMagnet ? targetMagnet.getAttribute('port') || targetMagnet.className.baseVal.split(' ')[1] : undefined
    const sourceCell = sourceCellView.model
    const targetCell = targetCellView.model
    const ends = {
      source: {
        ...getCellEnd(context, sourceCell),
        port: sourcePort
      },
      target: {
        ...getCellEnd(context, targetCell),
        port: targetPort
      }
    }
    const linkName = link.get('name')
    if (!paramModelIsLinkPossible(model, linkName, ends)) {
      return false
    }
    const paramLinkModel = paramModelGetLinkModel(model, linkName)
    if (paramLinkModel.allowMultiple) {
      return true
    } 
    const outboundLinks = graph.getConnectedLinks(sourceCellView.model, {outbound: true})
    return outboundLinks.every(outboundLink => outboundLink === linkView.model || outboundLink.get('name') !== paramLinkModel.name)
  }
}