import { contextGetParam } from './context'
import { paramShapesGetCellShape, paramShapeGetAllowResize } from './param-shapes'
import { objectConvert, booleanConvert } from './param-convert'
import { freeTransformRender, freeTransformClear } from './free-transform'
import { paramModelGetLinkModel } from './param-model'
import { haloRender } from './halo'

export const selectCell = (context, cellView) => {
  const cell = cellView.model
  const param = contextGetParam(context)
  let canBeSelected
  if (cell.isElement()) {
    haloRender(context, cellView)
    canBeSelected = true
  } else {
    const linkModel = objectConvert(paramModelGetLinkModel(param.model, cell.get('name')))
    canBeSelected = booleanConvert(linkModel.canBeSelected, false)
  }
  if (canBeSelected) {
    context.graph.getCells().forEach(aCell => {
      if (aCell.get('id') !== cell.get('id')) {
        if (aCell.get('selected')) {
          aCell.set('selected', false)
        }
      }
    })
    cell.set('selected', true)
  }
}
export const contextUpdateSelectedElements = context => {
  context.graph.getElements().forEach(element => {
    const elementView = element.findView(context.paper)
    const paramShape = paramShapesGetCellShape(context.paramShapes, element)
    const allowResize = paramShapeGetAllowResize(paramShape)
    if (element.get('selected')) {
      if (allowResize) {
        freeTransformRender(context, elementView)
      } else {
        elementView.highlight()
      }
    } else {
      if (allowResize) {
        if (context.freeTransform && context.freeTransform.cellView.model.get('id') == element.get('id')) {
          freeTransformClear(context)
        }
      } else {
        elementView.unhighlight()
      } 
    }
  })
  context.graph.getLinks().forEach(link => {
    const linkView = link.findView(context.paper)
    if (link.get('selected')) {
      linkView.highlight()
    } else {
      linkView.unhighlight()
    }
  })
}