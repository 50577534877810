import * as joint from '../rappid/rappid.min.js'
import { contextGetParam } from './context.js'
import { objectConvert, numberConvert } from './param-convert'

export const createPaperScroller = (paper, paperElement) => {
  const paperScroller = new joint.ui.PaperScroller({
    autoResizePaper: true,
    paper,
    padding: 0,
    cursor: 'grab',
   // baseWidth: 200,
    //baseHeight:  200,
    contentOptions: {
        minWidth: 800,
        minHeight: 600,
        padding:  0,
        allowNewOrigin: false
    }
  })

  paperElement.appendChild(paperScroller.render().el)
  return paperScroller
}

export const initializePaperScroller = (context, paperElement) => {
  const param = contextGetParam(context)
  context.paperScroller = createPaperScroller(context.paper, paperElement)
  const paramPaper = objectConvert(param.paper)
  if (paramPaper.zoom) {
    context.paperScroller.zoom(numberConvert(paramPaper.zoom, 1), {absolute: true})
    context.zoom = paramPaper.zoom 
  }
}