import * as joint from '../rappid/rappid.min.js'
import $ from 'jquery'

export const Modal = joint.mvc.View.extend({
  options: {
      padding: 20,
  },
  afterRender: function() {

  },
  render: function() {
    const self = this
    const position = self.options.position

    // disable page scrolling
    $('paper' ).css( 'overflow', 'hidden' )
    // put an overlay div in front of screen
    const $overlay = $('<div/>', {'class': 'overlay'})
    $('body').append($overlay)
    $overlay.on('click', () => self.cancel())
    $overlay.contextmenu(false)


    // puts element in front
    self.$el.css('z-index', 4)
    self.$el.on('click',false)

    $('body').append(self.$el)

    self.renderContent()

    self.$el.css({
      left: position.x,
      top: position.y
    })

    this.trigger('modal:create',self)
    self.afterRender()
    return self
  },
  cancel: function() {
      this.trigger('modal:canceled')
      this.remove()
  },
  onRemove: function() {
      $('.overlay').remove() // remove the overlay div
      $('paper' ).css( 'overflow', 'auto' ) // enable scrolling
      this.trigger('modal:remove')
  }
})
