import { attrsConvertBack, objectConvert, arrayConvert} from './param-convert'
import { paramGraphGetElement, paramGraphUpdateElement, paramGraphGetLink, paramGraphUpdateLink, paramGraphGetElements, paramGraphUpdateElements, paramGraphGetLinks, paramGraphUpdateLinks, paramVerticesConvertBack} from './param-graph'
import { objectSetPathValue } from './library'
import { contextUpdateSelectedElements } from './select-cell'
import { clone } from './library'

const handleChangeSimple = (diagram, data, property) => {
  const id = data.id
  const value = data.next[property]
  const element = paramGraphGetElement(diagram, id)
  const newElement = {
    ...element,
    [property]: value
  }
  return paramGraphUpdateElement(diagram, newElement)
}

const handleChangePosition = (diagram, data) => {
const id = data.id
  const position = data.next.position
  const element = paramGraphGetElement(diagram, id)
  const newElement = {
    ...element,
    position: {
      x: String(position.x),
      y: String(position.y)
    }     
  }
  return paramGraphUpdateElement(diagram, newElement)
}

const handleChangeSize = (diagram, data) => {
  const id = data.id
  const size = data.next.size
  const element = paramGraphGetElement(diagram, id)
  const newElement = {
    ...element,
    size: {
      width: String(size.width),
      height: String(size.height)
    }     
  }
  return paramGraphUpdateElement(diagram, newElement)
}

const handleChangeLinkVertices = (diagram, data) => {
  const id = data.id
  const vertices = data.next.vertices
  const link = paramGraphGetLink(diagram, id)
  const newLink = {
    ...link,
    vertice: paramVerticesConvertBack(vertices)
  }
  return paramGraphUpdateLink(diagram, newLink)
}

const handleChangeLinkTarget = (diagram, data) => {
  const id = data.id
  const target = data.next.target
  const link = paramGraphGetLink(diagram, id)
  const newLink = {
    ...link,
    target
  }
  return paramGraphUpdateLink(diagram, newLink)
}

const handleChangeLinkSource = (diagram, data) => {
  const id = data.id
  const source = data.next.source
  const link = paramGraphGetLink(diagram, id)
  const newLink = {
    ...link,
    source,
  }
  return paramGraphUpdateLink(diagram, newLink)
}


const handleAddElement = (diagram, data) => {
  const element = data.attributes
  element.attrs = attrsConvertBack(element.attrs)
  return paramGraphUpdateElements(diagram, [...paramGraphGetElements(diagram), element])
}

const handleAddLink = (diagram, data) => {
  const link = data.attributes
  return paramGraphUpdateLinks(diagram, [...paramGraphGetLinks(diagram), link])
}

const handleAdd = (diagram, data) => {
  if (data.attributes.source) {
    return handleAddLink(diagram, data)
  } else {
    return handleAddElement(diagram, data)
  }
}

const handleElementRemove = (diagram, data) => {
  const id = data.id
  const newElements = paramGraphGetElements(diagram).filter(element => element.id !== id)
  return paramGraphUpdateElements(diagram, newElements)
}

const handleLinkRemove = (diagram, data) => {
  const id = data.id
  const newLinks = paramGraphGetLinks(diagram).filter(link => link.id !== id)
  return paramGraphUpdateLinks(diagram, newLinks)
}

const handleRemove = (diagram, data) => {
  if (data.attributes.source) {
    return handleLinkRemove(diagram, data)
  } else {
    return handleElementRemove(diagram, data)
  }
}

const handleChangeAttrs = (diagram, data, options) => {
  const id = data.id
  const attrs = data.next.attrs
  const element = paramGraphGetElement(diagram, id)
  let newElement
  if (options && options.propertyPathArray) {
    const newAttrs = objectSetPathValue(objectConvert(element.attrs), options.propertyPathArray.slice(1), options.propertyValue)
    newElement = {
      ...element,
      attrs: newAttrs
    }
  } else {
    newElement = {
      ...element,
      attrs: attrsConvertBack(attrs)
    }
  } 
  return paramGraphUpdateElement(diagram, newElement)
}

const handleChangeLabels = (diagram, data, options) => {
  const id = data.id
  const link = paramGraphGetLink(diagram, id)
  let newLabels
  if (options && options.propertyPathArray) {
    const count = options.propertyPathArray[1]
    newLabels = arrayConvert(link.label).map((label, i) => {
      label = objectConvert(label)
      if (i === count) {
        return objectSetPathValue(label, options.propertyPathArray.slice(2), options.propertyValue) 
      } else {
        return label
      }
    })
  } else {
    newLabels = data.next.labels
  } 
  const newLink = {
    ...link,
    label: newLabels
  }
  return paramGraphUpdateLink(diagram, newLink)
}

const handleChangeUser = (diagram, data) => {
  const id = data.id
  const user = data.next.user
  const element = paramGraphGetElement(diagram, id)
  const newElement = {
    ...element,
    user: user
  }
  return paramGraphUpdateElement(diagram, newElement)
}

const handleChangeSelected = (diagram, data) => {
  const id = data.id
  const selected = data.next.selected
  const element = paramGraphGetElement(diagram, id)
  if (element) {
    const newElement = {
      ...element,
      selected
    }
    return paramGraphUpdateElement(diagram, newElement)
  } else {
    const link = paramGraphGetLink(diagram, id)
    const newLink = {
      ...link,
      selected
    }
    return paramGraphUpdateLink(diagram, newLink)
  }
}

const handleChangeEmbeds = (diagram, data) => {
  const id = data.id
  const embeds = data.next.embeds
  const element = paramGraphGetElement(diagram, id)
  const newElement = {
    ...element,
    embed: embeds
  }
  return paramGraphUpdateElement(diagram, newElement)
}

const handleChangeAngle = (diagram, data) => {
  const id = data.id
  const angle = data.next.angle
  const element = paramGraphGetElement(diagram, id)
  const newElement = {
    ...element,
    angle
  }
  return paramGraphUpdateElement(diagram, newElement)
}

const handleChangeParent = (diagram, data) => {
  const id = data.id
  const parent = data.next.parent
  const element = paramGraphGetElement(diagram, id)
  const newElement = {
    ...element,
    parent
  }
  return paramGraphUpdateElement(diagram, newElement)
}



const handleChangeEmbeddedLink = (diagram, data) => handleChangeSimple(diagram, data, 'embeddedLink')

const actionHandlers = {
  'change:position': handleChangePosition,
  'change:size': handleChangeSize,
  'change:vertices': handleChangeLinkVertices,
  'change:target': handleChangeLinkTarget,
  'change:source': handleChangeLinkSource,
  'change:attrs': handleChangeAttrs,
  'change:labels': handleChangeLabels,
  'change:user': handleChangeUser,
  'change:selected': handleChangeSelected,
  'change:embeds': handleChangeEmbeds,
  'change:parent': handleChangeParent,
  'change:embeddedLink': handleChangeEmbeddedLink,
  'change:angle': handleChangeAngle,
  'add': handleAdd,
  'remove': handleRemove
}

export const handleChange = (param, change) => {
  if (Array.isArray(change)) {
    change.forEach(ch => handleChange(param, ch) )
  } else {
    const action = change.action
    const data = change.data
    const graph = objectConvert(param.graph)
    const handler = actionHandlers[action]
    if (handler) {
      param.graph = handler(graph, data, change.options)
    }
  }
}

export const contextHandleChange = (context, change) => {
  if (context.withoutChangesLevel === 0) {
    const arrayChanges = Array.isArray(change) ? change : [change]
    const validChange = arrayChanges.every(change => {
      if (change.action === 'change:target' && !change.data.next.target.id) {
        return false
      }
      if (change.action === 'change:source' && !change.data.next.source.id) {
        return false
      }
      return true
    }) 
    if (validChange) {
      const param = context.props.data.param
      contextUpdateSelectedElements(context)
      handleChange(param, change)
      context.displayedParamGraph = clone(param.diagram)
  
      param.change = arrayChanges.map(change => {
        return {action: change.action, data: change.data}
      })
      if (change.options &&  change.options.textEditor) {
        if (context.changeTimeout) {
          clearTimeout(context.changeTimeout)
        }
        context.changeTimeout = setTimeout(() => {
          MC.handleEvent(context.props.data, 'change')
        }, 500)
      } else {
        MC.handleEvent(context.props.data, 'change')
      }
    }
  }
}