import { arrayConvert, attrsConvert, booleanConvert, labelPositionConvert, objectConvert} from './param-convert';
import { concatArrays, isDefined } from './library';

export const paramGraphGetLinks = graph => arrayConvert(graph.link)

export const paramGraphGetElements = graph => arrayConvert(graph.element)

const ensureGraphNotNull = graph => arrayConvert(graph.link).length === 0  && arrayConvert(graph.element).length === 0  ? '' : graph

export const paramGraphUpdateElements = (graph, elements) => {
  return ensureGraphNotNull({...graph, element: elements})
}

export const paramGraphUpdateLinks = (graph, links) => {
  return ensureGraphNotNull({...graph, link: links})
}

export const paramGraphUpdateElement = (graph, newElement) => {
  const newElements = paramGraphGetElements(graph).map(element => {
    if (element.id === newElement.id) {
      return newElement   
    } else {  
      return element
    }
  })
  return paramGraphUpdateElements(graph, newElements)
}

export const paramGraphUpdateLink = (graph, newLink) => {
  const newLinks = paramGraphGetLinks(graph).map(link => {
    if (link.id === newLink.id) {
      return newLink   
    } else {  
      return link
    }
  })
  return paramGraphUpdateLinks(graph, newLinks)
}

export const paramGraphGetElement = (graph, id) => paramGraphGetElements(graph).find(element => element.id == id) 

export const paramGraphGetLink = (graph, id) => paramGraphGetLinks(graph).find(link => link.id == id) 

export const paramLinkGetEmbeddable = paramLink => booleanConvert(paramLink.embeddable, false)

export const paramVerticesConvert = vertices => {
  return arrayConvert(vertices).map(vertice => {
    return {
      x: Number(vertice.x),
      y: Number(vertice.y)
    }
  })
}

export const paramVerticesConvertBack = vertices => {
  return vertices.map(vertice => {
    return {
      x: String(vertice.x),
      y: String(vertice.y)
    }
  })
}

export const paramSizeConvert = (size = {} ) => {
  return {
    width: Number(size.width),
    height: Number(size.height)
  }
}

export const paramPositionConvert = position => {
  if (!position) {
    return {x: 0, y: 0}
  }
  return {
    x: Number(position.x),
    y: Number(position.y)
  }
}



const paramLinkEndConvert = paramPoint => {
  const result = {id: paramPoint.id}
  if (isDefined(paramPoint.port)) {
    result.port = paramPoint.port
  }
  if (isDefined(paramPoint.selector)) {
    result.selector = paramPoint.selector
  }
  if (isDefined(paramPoint.anchor)) {
    result.anchor = paramPoint.anchor
  } 
  return result
}


const paramLabelConvert = paramLabel => {
  paramLabel = objectConvert(paramLabel)
  const label = {}
  if (paramLabel.attrs) {
    label.attrs = attrsConvert(paramLabel.attrs)
  }
  if (paramLabel.position) {
    label.position = labelPositionConvert(paramLabel.position)
  }
  return label
}

export const paramLabelsConvert = paramLabels => {
  return arrayConvert(paramLabels).map(paramLabelConvert)
}

const paramLinkConvert = link => {
  const result = {
    id: link.id,
    source: paramLinkEndConvert(link.source),
    target: paramLinkEndConvert(link.target),
    vertices: paramVerticesConvert(link.vertice),
    type: link.type 
  }
  if (link.attrs) {
    result.attrs = link.attrs
  }
  if (typeof link.z !== 'undefined' ) {
    result.z = Number(link.z)
  }
  if (link.embeddable) {
    result.embeddable = true
  }
  if (link.label) {
    result.labels = paramLabelsConvert(link.label)
  }
  return result;
}

const paramElementConvert = element => {
  const result = {
    id: element.id,
    type: element.type,
    position: paramPositionConvert(element.position),
    z: 0
  }
  if (element.size) {
    result.size = paramSizeConvert(element.size) 
  }
  if (element.z) {
    result.z = Number(element.z)
  }

  if (element.angle) {
    result.angle = Number(element.angle)
  }

  if (element.attrs) {
    result.attrs = attrsConvert(element.attrs)
  }
  if (element.parent) {
    result.parent = element.parent
  }

  if (element.user) {
    result.user = element.user
  }

  if (element.embeddedLink) {
    result.embeddedLink = element.embeddedLink
  }
  result.selected = booleanConvert(element.selected, false)
  return result
}

const cellsAddEmbeds = cells => {
  return cells.map(cell => {
    const embeds = cells.filter(c => c.parent === cell.id).map(c => c.id)
    return {...cell, embeds}
  })
}

const linksAddParent = (elements, links) => {
  return links.map(link => {
    const sourceElementId = link.source.id
    const targetElementId = link.target.id
    const sourceElement = elements.find(element => element.id === sourceElementId)
    const targetElement = elements.find(element => element.id === targetElementId)
    if (sourceElement.parent === targetElement.parent) {
      return {...link, parent: sourceElement.parent}
    } else {
      return link
    }
  })
}

const cellsParentZIndexResolve = (cells, parent) => {
  const children =  cells.filter(cell => cell.parent === parent.id)
  const childrenResolved = children.map(child => {
    return {...child, z: parent.z + 1}
  })
  const deepChildren = concatArrays(childrenResolved.map(child => cellsParentZIndexResolve(cells, child)))
  return [parent].concat(deepChildren)
}

const cellsZIndexResolve = cells => concatArrays(cells.filter(cell => !cell.parent).map(parent => cellsParentZIndexResolve(cells, parent)))

export const paramGraphConvert = graph => {
  if (!graph) {
    return {cells: []}
  }
  const links = arrayConvert(graph.link)
  const elements = arrayConvert(graph.element)
  const linkCells = links.map(paramLinkConvert) //linksAddParent(elements, )
  const elementCells = cellsAddEmbeds(elements.map(paramElementConvert))
  const cells = linkCells.concat(elementCells)

  return {cells: cellsZIndexResolve(cellsAddEmbeds(cells))}
}