export const arrayConvert = value => {
  if (typeof value === 'undefined') {
    return []
  }
  if (value.length === 1 && (value[0] === null || value[0] === '' || typeof value[0] === 'undefined')) {
    return []
  }
  if (Array.isArray(value)) {
    return value
  }
  return [value]
}

export const objectConvert = value => {
  if (value === '') {
    return {}
  }
  if (value === null) {
    return {}
  }
  if (typeof value === 'undefined') {
    return {}
  }
  if (Array.isArray(value)) {
    return objectConvert(value[0])
  }
  if (typeof value === 'object') {
    return value
  }
  throw new Error('Can not convert value to object.')
}

export const labelPositionConvert = value => 
{
  const position = {}
  if (value.offset) {
    const offset = {}
    if (value.offset.x) 
      offset.x = numberConvert(value.offset.x, 0)
    if (value.offset.y) 
      offset.y = numberConvert(value.offset.y, 0)
    position.offset = offset
  }
  if (value.distance) 
    position.distance = numberConvert(value.distance, 0)
  if (value.angle) 
      position.angle = numberConvert(value.angle, 0)
  return position
}

const valueConvert = (value, dataType) => {
  switch (dataType) {
    case 'number':
      if (typeof value === 'number') {
        return value
      }
      if (typeof value === 'string') {
        return value.endsWith('%') ? value : Number(value)
      }
      throw new Error('Can not convert value ' + value + ' to number.')
      
    case 'boolean':
      if (typeof value === 'boolean') {
        return value
      } else {
        return value === 'true'
      }
    default:
      return value
  }
}


export const paramArrayGet = (param, key) => arrayConvert(param[key])

const attrType = {
  width:  'number',
  height:  'number',
  x: 'number',
  y: 'number',
  z: 'number',
  'ref-x': 'number',
  'ref-y': 'number',
  'font-size': 'number',
  'follow-scale': 'boolean',
}

export const attrsConvertBack = attrs => {
  if (typeof attrs === 'undefined' || attrs === null) {
    return {}
  }
  if (Array.isArray(attrs)) {
    return attrsConvertBack(attrs[0])
  }
  if (!(attrs instanceof Object)) {
    return String(attrs)
  }
  const result = {}
  Object.keys(attrs).forEach(key => {
    result[key] = attrsConvertBack(attrs[key])
  })
  return result
}

export const attrsConvert = attrs => {
  if (typeof attrs === 'undefined' || attrs === null) {
    return {}
  }
  if (Array.isArray(attrs)) {
    return attrsConvert(attrs[0])
  }
  if (!(attrs instanceof Object)) {
    return attrs
  }
  const result = {}
  const ordianryKeys = Object.keys(attrs).filter(key => key !== 'nameValuePair')
  ordianryKeys.forEach(key => {
    let resultKey = key.startsWith('dot_') ? '.' + key.substr(4) :  key
    const value = attrsConvert(attrs[key])
    const dataType = attrType[key]

    if (value instanceof Object) {
      result[resultKey] = value
    } else {
      result[resultKey] = valueConvert(value, dataType)
    }
  })
  const nameValuePairs = arrayConvert(attrs.nameValuePair)
  nameValuePairs.forEach(({name, value}) => {
    result[name] = attrsConvert(value)
  })
  return result
}

export const booleanConvert = (value, defaultValue) => {
  if (typeof value === 'boolean') {
    return value
  }
  if ((typeof value === 'undefined' || value === '' || value === null) && typeof defaultValue !== 'undefined') {
    return defaultValue
  }
  if (value === 'true') {
    return true
  }
  if (value === 'false') {
    return false
  }
  if (typeof value === 'undefined' || value === '' || value === null) {
    return undefined
  }
  throw new Error(`Can not convert ${value} to boolean.`)
}

export const numberConvert = (value, defaultValue) => {
  if (typeof value === 'number') {
    return value
  }
  if (typeof value === 'undefined' || value === '' || value === null) {
    return undefined
  }
  if (typeof value === 'string') {
    return Number(value)
  }
  if ((typeof value === 'undefined' || value === '' || value === null) && typeof defaultValue !== 'undefined') {
    return defaultValue
  }

  throw new Error(`Can not convert ${value} to number.`)
}

export const paramInteractiveConvert = interactive => {
  if (!interactive) {
    return {}
  }
  return {
    addCells: booleanConvert(interactive.addCells)
  }
}

export const paramLayoutConver = (paramLayout = {}) => {
  return {
    columns: numberConvert(paramLayout.columns),
    columnWidth: numberConvert(paramLayout.columnWidth),
    rowHeight: numberConvert(paramLayout.rowHeight),
    centre: booleanConvert(paramLayout.centre),
    dx: numberConvert(paramLayout.dx),
    dy: numberConvert(paramLayout.dy),
    resizeToFit: booleanConvert(paramLayout.resizeToFit, true),
    marginX: numberConvert(paramLayout.marginX),
    marginY: numberConvert(paramLayout.marginY)
  }
}