export const callWithoutChange = (context, bodyFunction) => {
  context.withoutChangesLevel++
  try {
    bodyFunction()
  } finally {
    context.withoutChangesLevel--
  }
}

export const callAsBatch = (context, bodyFunction) => {
  context.commandManager.initBatchCommand()
  try {
    bodyFunction()
  } finally {
    context.commandManager.storeBatchCommand()
  }
}

export const contextGetParam = context => context.props.data.param