import { paramModelGetElements, paramModelElementGetShapes, paramModelGetLinks, paramModelLinkGetShapes } from './param-model'


export const createShapeTypeModelMap  = paramModel => {
  const shapeTypeModelMap = {}
  paramModelGetElements(paramModel).forEach(element => {
    const shapes = paramModelElementGetShapes(element)
    shapes.forEach(shape => {
      shapeTypeModelMap[shape.type] = element
    })
  })
  paramModelGetLinks(paramModel).forEach(link => {
    const shapes = paramModelLinkGetShapes(link)
    shapes.forEach(shape => {
      shapeTypeModelMap[shape.type] = link
    })
  })
  return shapeTypeModelMap
}

export const createShapeMap  = paramModel => {
  const shapeMap = {}
  paramModelGetElements(paramModel).forEach(element => {
    const shapes = paramModelElementGetShapes(element)
    shapes.forEach(shape => {
      shapeMap[shape.type] = shape
    })
  })
  paramModelGetLinks(paramModel).forEach(link => {
    const shapes = paramModelLinkGetShapes(link)
    shapes.forEach(shape => {
      shapeMap[shape.type] = shape
    })
  })
  return shapeMap
}

export const getParamShape = (context, shapeType) => context.shapeMap[shapeType]

export const getParamShapeModel = (context, shapeType) => context.shapeTypeModelMap[shapeType]
