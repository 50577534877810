export const paramPaperGetDrawGrid = paramGrid => {
  if (typeof paramGrid === 'undefined') {
    return false
  }
  return true
}

export const paramPaperGetGridSize = paramGrid => {
  if (typeof paramGrid === 'undefined') {
    return 1
  }
  return Number(paramGrid.size)
}