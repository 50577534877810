import * as joint from '../rappid/rappid.min.js'
import { paramShapeGetShowLinkTools } from './param-shapes'
import { getParamShape } from './data-maps'


export const registerLinkToolsHandlers = context => {
  const paper = context.paper

  paper.on('link:mouseenter', linkView => {
    const link = linkView.model
    const linkShape = getParamShape(context, link.get('type'))
    if (paramShapeGetShowLinkTools(linkShape)) {
      const sourceArrowheadTool = new joint.linkTools.SourceArrowhead()
      const targetArrowheadTool = new joint.linkTools.TargetArrowhead()
      const toolsView = new joint.dia.ToolsView({
          tools: [sourceArrowheadTool, targetArrowheadTool]
      })
  
      linkView.addTools(toolsView)
      linkView.showTools()
    }
  })

  paper.on('blank:mouseover', () => {
    paper.hideTools()
  })
}