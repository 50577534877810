import {arrayConvert, objectConvert, attrsConvert, booleanConvert, numberConvert} from './param-convert'
import {clone, equals, removeDuplicates, ensureObject} from './library'
import {paramPositionConvert, paramVerticesConvert, paramSizeConvert, paramLabelsConvert} from './param-graph'
import {jointCreateElement} from './joint-shape'
import {elementConnectedLinksEmbeddingResolve} from './embedding'
import {updateCellVisual} from './swith-cell-visual'
import {callWithoutChange, contextGetParam} from './context'
import {paramShapesGetShapeArray} from './param-shapes'

const extendAttrPath = (path, step) => {
  if (path === '') {
    return step
  } else {
    return path + '/' + step
  }
}

const updateAttr = (cell, path, attrs, prevAttrs, shapeAttrs) => {
  if (!equals(attrs, prevAttrs)) {
    if (typeof attrs === 'object') {
      const keys = removeDuplicates(Object.keys(ensureObject(attrs)).concat(Object.keys(ensureObject(prevAttrs))))
      keys.forEach(key => {
        updateAttr(cell, extendAttrPath(path, key), ensureObject(attrs)[key], ensureObject(prevAttrs)[key], ensureObject(shapeAttrs)[key])
      })
    } else {
      if (attrs !== prevAttrs) {
        if (typeof attrs !== 'undefined') {
          cell.attr(path, attrs)
        } else {
          cell.attr(path, shapeAttrs || '')
        }
      }
    }
  }
}

const updateAttrs = (shape, cell, paramAttrs, prevParamAttrs) => {
  const shapeAttrs = attrsConvert(objectConvert(shape).attrs)
  const attrs = attrsConvert(paramAttrs)
  const prevAttrs = attrsConvert(prevParamAttrs)
  updateAttr(cell, '', attrs, prevAttrs, shapeAttrs)
  //element.attr(attrs)
}

const updateElement = (shape, graph, element, paramElement, prevParamElement) => {
  prevParamElement = objectConvert(prevParamElement)
  if (!equals(paramElement.attrs, prevParamElement.attrs)) {
    updateAttrs(shape, element, paramElement.attrs, prevParamElement.attrs)
  }
  if (!equals(paramElement.selected, prevParamElement.selected)) {
    element.set('selected', booleanConvert(paramElement.selected))
  }
  if (!equals(paramElement.size, prevParamElement.size)) {
    element.set('size', paramSizeConvert(paramElement.size))
  }
  if (!equals(paramElement.position, prevParamElement.position)) {
    element.set('position', paramPositionConvert(paramElement.position))
  }
  if (!equals(paramElement.user, prevParamElement.user)) {
    element.set('user', clone(paramElement.user))
  }
  if (!equals(paramElement.angle, prevParamElement.angle)) {
    element.set('angle', numberConvert(paramElement.angle, 0))
  }
  if (!paramElement.parent && prevParamElement.parent) {
    const parent = element.getParentCell()
    parent.unembed(element)
    elementConnectedLinksEmbeddingResolve(graph, element)
  }
  if (paramElement.parent && !prevParamElement.parent) {
    const parent = graph.getCell(paramElement.parent)
    parent.embed(element)
    elementConnectedLinksEmbeddingResolve(graph, element)
  }
  if (!equals(paramElement.embeddedLink, prevParamElement.embeddedLink)) {
    element.set('embeddedLink', paramElement.embeddedLink)
  }
}

const updateElements = (context, shapes, graph, paramElements, prevParamElements) => {
  arrayConvert(paramElements).forEach(paramElement => {
    let prevParamElement = arrayConvert(prevParamElements).find(prevParamElement => prevParamElement.id === paramElement.id)
    let element
    if (prevParamElement) {
      element = graph.getCell(paramElement.id)
    }
    if (prevParamElement && prevParamElement.type !== paramElement.type) {
      element = updateCellVisual(graph, element, paramElement.type)
      prevParamElement = undefined
    }
    if (!prevParamElement) {
      element = jointCreateElement(paramElement.type, paramElement.id)
      graph.addCell(element)
    }
    if (!equals(paramElement, prevParamElement)) {
      const shape = arrayConvert(shapes).find(shape => shape.type === paramElement.type)
      updateElement(shape, graph, element, paramElement, prevParamElement)
    }
  })
}

const updateLink = (shape, link, paramLink, prevParamLink) => {
  prevParamLink = objectConvert(prevParamLink)
  if (!equals(paramLink.attrs, prevParamLink.attrs)) {
    updateAttrs(shape, link, paramLink.attrs, prevParamLink.attrs)
  }
  if (!equals(paramLink.vertice, prevParamLink.vertice)) {
    link.set('vertices', paramVerticesConvert(paramLink.vertice))
  }
 
  if (!equals(paramLink.source, prevParamLink.source)) {
    link.set('source', paramLink.source || {})
  }
  if (!equals(paramLink.target, prevParamLink.target)) {
    link.set('target', paramLink.target || {})
  }  
  if (!equals(paramLink.embeddable, prevParamLink.embeddable)) {
    link.set('embeddable', paramLink.embeddable)
  }
  if (!equals(paramLink.label, prevParamLink.label)) {
    link.labels(paramLabelsConvert(paramLink.label))
  }
}

const updateLinks = (shapes, graph, paramLinks, prevParamLinks) => {
  arrayConvert(paramLinks).forEach(paramLink => {
    const prevParamLink = arrayConvert(prevParamLinks).find(prevParamLink => prevParamLink.id === paramLink.id)
    let link
    if (prevParamLink) {
      link = graph.getCell(paramLink.id)
    } else {
      link = jointCreateElement(paramLink.type, paramLink.id)
      graph.addCell(link)
    }
    const shape = arrayConvert(shapes).find(shape => shape.type === paramLink.type) 
    updateLink(shape, link, paramLink, prevParamLink)
  })

}

const removeUnusedLinks = (graph, paramLinks, prevParamLinks) => {
  arrayConvert(prevParamLinks).forEach(prevParamLink => {
    const paramLink = arrayConvert(paramLinks).find(paramLink => paramLink.id === prevParamLink.id)
    if (!paramLink) {
      const link = graph.getCell(prevParamLink.id)
      link.remove()
    }
  })
}

const removeUnusedElements = (graph, paramElements, prevParamElements) => {
  arrayConvert(prevParamElements).forEach(prevParamElement => {
    const paramElement = arrayConvert(paramElements).find(paramElement => paramElement.id === prevParamElement.id)
    if (!paramElement) {
      const element = graph.getCell(prevParamElement.id)
      element.remove()
    }
  })
}

export const updateGraph = (context, shapes, graph, diagram, prevDiagram) => {
  prevDiagram = objectConvert(prevDiagram)
  diagram = objectConvert(diagram)
  updateElements(context, shapes, graph, diagram.element, prevDiagram.element)
  updateLinks(shapes, graph, diagram.link, prevDiagram.link)
  removeUnusedLinks(graph, diagram.link, prevDiagram.link)
  removeUnusedElements(graph, diagram.element, prevDiagram.element)
}

export const contextUpdateGraph = (context, diagram, prevDiagram) => {
  callWithoutChange(context, () => {
    const param = contextGetParam(context)
    const shapes = paramShapesGetShapeArray(context.paramShapes)
    context.commandManager.initBatchCommand()
    updateGraph(context, shapes, context.graph, diagram, prevDiagram)
    context.commandManager.storeBatchCommand()
    context.displayedParamGraph = clone(diagram)
  })
}