import {Modal} from './modal.js'
import $ from 'jquery'

export const Dialog = Modal.extend({
  className: 'cell-dialog',
  eventNamespace: 'cell-dialog',
  events: {
    'click form button[name="OK"]': 'onOK',
    'click form button[name="cancel"]': 'onCancel'
  },
  template: _.template('<div class="field"><label><%= label %></label><input id="mainInput" type="text" id="dialogInput" name="name" value="<%= value %>"></div>' +
        '<button name="OK" class="ui primary button">OK</button> <button name="cancel" class="ui button"><%= cancel %></button>'),
  options: _.create(Modal.prototype.options,{label: 'Name'}),
  start: function() {

  },
  renderContent: function() {
    const $html = $('<div/>', {'class': 'ui segment'})
    const $form = $('<form class="ui form" id="dialogform"/>')
    $html.append($form);
    const temp = this.template({
        label: this.options.label,
        value: this.options.value,
        cancel: 'Cancel'
    })
    $form.append(temp)
    this.$el.append($html)
    this.start()
  },
  afterRender: function() {
    this.$el.find("input[name='name']").focus()
  },
  onOK: function(evt) {
    evt.preventDefault()
    this.remove()
    this.options.resolve(this.$el.find("input[name='name']").val())
  },
  onCancel: function(evt) {
    evt.preventDefault()
    this.cancel()
    this.options.resolve(this.options.value)
  }
})

export const OpenDialog = function(options) {
  return new Promise(resolve => {
    const dialogOptions = {...options, resolve}
    const dialog = new Dialog(dialogOptions)
    dialog.render()
  })  
}
