window._CONNECTORS = {handlers: {}, values: {}}

export const produce = (name, value) => {
  if (_CONNECTORS.handlers[name]) {
    _CONNECTORS.handlers[name].forEach(handler => handler(value))
  }
  _CONNECTORS.values[name] = value
}

export const unproduce = name => {
  delete _CONNECTORS.handlers[name]
  delete _CONNECTORS.values[name]
}

export const consume = (name, handler) => {
  if (_CONNECTORS.values[name]) {
    handler(_CONNECTORS.values[name])
  } else {
    if (!_CONNECTORS.handlers[name]) {
      _CONNECTORS.handlers[name] = []
    } 
    _CONNECTORS.handlers[name].push(handler)

  }
}