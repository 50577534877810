export const freeTransformRender = (context, elementView) => {
  const element = elementView.model
  const freeTransformConfig = {cellView: elementView, allowRotation: false}
  if (element.get('preserveAspectRatio')) {
    freeTransformConfig.preserveAspectRatio = true
  }
  if (element.get('allowRotation')) {
    freeTransformConfig.allowRotation = true
  }
  if (element.get('minSize')) {
    const minSize = element.get('minSize')
    freeTransformConfig.minWidth = minSize.width
    freeTransformConfig.minHeight = minSize.height   
  }
  const freeTransform = new joint.ui.FreeTransform(freeTransformConfig)
  freeTransform.render()
  context.freeTransform = {
    view: freeTransform,
    cellView: elementView
  }
}

export const freeTransformClear = context => {
  context.freeTransform.view.remove()
  delete context.freeTransform
}