import { contextGetParam } from './context'
import { arrayConvert } from './param-convert'
import { contextHandleChange } from './changes'

const createCommandManager = context => {
  const graph = context.graph
  const cmdBeforeAdd = (cmdName, cell, _graph, _options) => {
    if (cmdName === 'change:z' ) {
      return false
    } 
    if (cell.get('temporary')) {
      return false
    }
    return true
  }
  const commandManager = new joint.dia.CommandManager({graph, cmdBeforeAdd})
  window.commandManager = commandManager
  commandManager.on('add', change => contextHandleChange(context, change))
  return commandManager
}


export const paramCommandManagerUpdate = (context, change, isUndo) => {
  const param = contextGetParam(context)
  if (!param.commandManager) {
    param.commandManager = {}
  }
  param.commandManager.hasUndo = context.commandManager.hasUndo()
  param.commandManager.hasRedo = context.commandManager.hasRedo()
  if (change) {
    param.commandManager.command = {change: arrayConvert(change).map(ch => {
      return {action: ch.action}
    }), isUndo}
  } else {
    param.commandManager.command = null
  }
}

export const initializeCommandManager = context => {
  context.commandManager = createCommandManager(context)
  //window.commandManager = commandManager
}