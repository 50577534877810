import * as joint from '../rappid/rappid.min.js'

export const createValidator = (context) => {
  const commandManager = context.commandManager
  const validator = new joint.dia.Validator({ commandManager, cancelInvalid: true })
  const isLink = (err, command, next) => {
    const cell = context.graph.getCell(command.data.id)
    if (cell && cell.get('temporary')) {
      return
    }
    if (context.withoutChangesLevel !== 0) {
      return
    }
    if (cell.isLink()) {
      return next(err)
    }
  }
  const connectivity = (err, command, next) => {
    const link = context.graph.getCell(command.data.id)
 
    const sourceId = link.get('source').id
    const targetId = link.get('target').id

    if (sourceId && targetId) {
      return next(err)
    } else {
      return next('Links can\'t be pinned to the paper')
    }
  }
  validator.validate('change:source change:target add', isLink, connectivity)
  return validator
}

export const initializeValidator = context => {
  context.validator = createValidator(context)
}