import * as joint from '../rappid/rappid.min.js'
import {isDefined} from './library'
import {arrayConvert} from './param-convert'
import {paramShapeConvert} from './param-shapes'

export const jointGetShape = type => {
  if (typeof type === 'undefined') {
    throw new Error('Type is not defined')
  }
  const typeParts = type.split(".")
  if (typeParts.length == 1) {
    if (typeParts[0] === 'Element') {
      return joint.dia.Element
    } else if (typeParts[0] === 'Link') {
      return joint.dia.Link
    }
  } else if (typeParts.length === 2) {
    const modelName = typeParts[0]
    const cellName = typeParts[1]
    if (joint.shapes[modelName]) {
      return joint.shapes[modelName][cellName]
    }
  }
}

export const jointCreateElement = (type, id) => {
  const shape = jointGetShape(type)
  return new shape({id})
}

const jointDefineLinkShape = paramShape => {
  const shape = paramShapeConvert(paramShape)
  const defaultPrototype = 'Link'
  const prototype = jointGetShape(shape.prototype || defaultPrototype)
  if (!prototype) {
    throw new Error(`Prototype ${shape.prototype} of shape ${shape.type} is not defined.`)
  }
  const {markup, ...rest} = shape 
  const prototypeProperties = {}
  if (isDefined(markup)) {
    prototypeProperties.markup = markup
  }
  const defaultInstanceProperties = rest
  prototype.define(shape.type, defaultInstanceProperties, prototypeProperties)
}

const jointDefineElementShape = paramShape => {
  const shape = paramShapeConvert(paramShape)
  const defaultPrototype = 'Element'
  const prototype = jointGetShape(shape.prototype || defaultPrototype)
  if (!prototype) {
    throw new Error(`Prototype ${shape.prototype} of shape ${shape.type} is not defined.`)
  }
  const {markup, ...rest} = shape 
  const prototypeProperties = {}
  if (isDefined(markup)) {
    prototypeProperties.markup = markup
  }
  const defaultInstanceProperties = rest
  prototype.define(shape.type, defaultInstanceProperties, prototypeProperties)
}

const shapeDefine = (shape, isElement) => {
  if (!jointGetShape(shape.type)) {
    if (isElement) {
      jointDefineElementShape(shape)
    } else {
      jointDefineLinkShape(shape)
    }
  }
}

const shapesDefine = (shapes, isElement) => {
  shapes.forEach(shape => shapeDefine(shape, isElement))
}

export const jointDefineShapes = paramShapes => {
  shapesDefine(arrayConvert(paramShapes.link), false)
  shapesDefine(arrayConvert(paramShapes.element), true)
}

export const jointDefineElementShapes = paramElementShapes => {
  shapesDefine(arrayConvert(paramElementShapes), true)
}