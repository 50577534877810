import {paramModelGetPossibleLinks} from './param-model'
import {paramShapesGetModelCellsShapes, paramShapesGetLinks} from './param-shapes'
import {objectConvert} from './param-convert'
import { contextGetParam } from './context'

export const paramGetGraph = param => objectConvert(param.graph)

export const paramGetModel = param => objectConvert(param.model)

export const paramGetPaper = param => objectConvert(param.paper)

export const paramGetPossibleLinksShapes = (context, sourceElementName, targetElementName) => {
  const param = contextGetParam(context)
  const shapes = context.paramShapes
  let linkShapes = paramShapesGetLinks(shapes)
  const model = objectConvert(param.model)
  if (model) {
    const possibleLinks = paramModelGetPossibleLinks(param.model, sourceElementName, targetElementName)
    linkShapes = paramShapesGetModelCellsShapes(shapes, possibleLinks)
  }
  return linkShapes
}

