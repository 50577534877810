import {arrayConvert, objectConvert} from './param-convert'
import { concatArrays } from './library'

export const paramModelGetLinks = model => arrayConvert(objectConvert(model).link)

export const paramModelGetElements = model => arrayConvert(objectConvert(model).element)

export const paramModelCellGetShapes = link => arrayConvert(link.shape)

export const paramModelLinkGetTitle = link => link.title || link.name

export const paramModelLinkGetShapes = link => arrayConvert(link.shape)

export const paramModelLinkGetPossibilities = link => arrayConvert(link.possibility)

export const paramModelElementGetShapes = link => arrayConvert(link.shape)

export const paramModelGetElement = (model, elementName) => {
  return paramModelGetElements(model).find(element => element.name === elementName)
}

const endEqual = (end1, end2) => {
  return end1.elementName === end2.elementName && end1.port === end2.port && end1.linkName === end2.linkName
}

const endsEqual = (ends1, ends2) => endEqual(ends1.source, ends2.source) && endEqual(ends1.target, ends2.target)

export const pramModelLinkIsPossible = (link, ends) => {
  return paramModelLinkGetPossibilities(link).find(possibility => endsEqual(possibility, ends))
}

export const pramModelLinkIsSourcePossible = (link, sourceElementName, sourcePort) => {
  return paramModelLinkGetPossibilities(link).find(possibility => {
    return possibility.source.elementName === sourceElementName && possibility.source.port === sourcePort 
  })
}


export const paramModelGetPossibleLinks = (model, ends) => {
  return paramModelGetLinks(model).filter(link => {
    return pramModelLinkIsPossible(link, ends)
  })
}

export const paramModelGetPossibleOutcommingModelLinks = (model, sourceElementName, sourcePort) => {
  return paramModelGetLinks(model).filter(link => {
    return pramModelLinkIsSourcePossible(link, sourceElementName, sourcePort)
  })
}

export const paramModelGetLinkModel = (model, linkName) => {
  return paramModelGetLinks(model).find(link => link.name === linkName)
}

export const paramModelIsLinkPossible = (model, linkName, ends) => {
  const link = paramModelGetLinkModel(model, linkName)
  return pramModelLinkIsPossible(link, ends)
}

export const paramModelGetShapes = model => {
  const elementShapes = concatArrays(paramModelGetElements(model).map(element => {
    return paramModelElementGetShapes(element).map(shape => {
      return {...shape, name: element.name}
    })
  }))
  const linkShapes = concatArrays(paramModelGetLinks(model).map(link => {
    return paramModelLinkGetShapes(link).map(shape => {
      return {...shape, name: link.name}
    })
  }))
  return {
    link: linkShapes,
    element: elementShapes
  }
}