import Backbone from 'backbone'

const getSelectedElements = context => {
  const selectedElement = context.graph.getElements().find(element => element.get('selected'))
  if (selectedElement) {
    const elements = new Backbone.Collection
    elements.add(selectedElement)
    return elements
  } else {
    return context.selection.collection
  }
}

export const initializeKeyboard = context => {
  context.keyboard = new joint.ui.Keyboard()

  context.clipboard = new joint.ui.Clipboard({ useLocalStorage: true })

  context.keyboard.on({
    'ctrl+a command+a': event => { 
      event.preventDefault()
      context.selection.collection.reset(context.graph.getElements())
    },

    'ctrl+c command+c': () => { 
      const selectedElements = getSelectedElements(context)
      context.clipboard.copyElements(selectedElements, context.graph, {
        translate: {
          dx: 20,
          dy: 20
        }
      })
    },

    'ctrl+v command+v': () => { 
      context.clipboard.pasteCells(context.graph)
    },

    'ctrl+x command+x': () => { 
      const selectedElements = getSelectedElements(context)
      context.clipboard.cutElements(selectedElements, context.graph)
    },

    'delete backspace': () => { 
      context.commandManager.initBatchCommand()
      const selectedElements = getSelectedElements(context)
      selectedElements.models.forEach(cell => cell.remove())
      context.commandManager.storeBatchCommand()
    },

    'keydown:shift': () => {
      context.paperScroller.setCursor('crosshair')
    },

    'keyup:shift': function() {
      context.paperScroller.setCursor('grab')
    }
  })
}